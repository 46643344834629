import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, Observable, shareReplay, take, throwError } from 'rxjs';
import { Page } from '../../@common/entities/page.interface';
import { ApiPath } from '../../@common/http/api-path.enum';
import { Logger } from '../../@common/log/logger';
import { UserService } from '../user/application/user.service';
import { Video } from '../video/entities/video.interface';
import { History } from './history.interface';

@Injectable({
    providedIn: 'root',
})
export class HistoryService {
    private http = inject(HttpClient);
    private userService = inject(UserService);
    private logger = inject(Logger);

    getHistory(hits: string, limit: number, offset: number, sort: string): Observable<Page<History>> {
        if (sort !== 'published' && sort !== 'views' && sort !== 'title' && sort !== 'starred') {
            sort = '';
        }

        return this.http.get<Page<History> & { lecture: Video }>(
            `${ ApiPath.history }/filter/?hits=${ hits }&limit=${ limit }&offset=${ offset }&sort=${ sort }`,
            { withCredentials: true }
        ).pipe(
            shareReplay(1),
            catchError(e => {
                this.logger.apiError('Get history failed', e);
                throw throwError(() => e);
            })
        );
    }

    addHistory(historyData: History): void {
        if (!this.userService.isAuthenticated()) {
            return;
        }

        this.http.post<unknown>(ApiPath.historyAdd + '/', historyData, { withCredentials: true })
            .pipe(
                take(1),
                catchError(e => {
                    this.logger.apiError('Add history failed', e);
                    throw throwError(() => e);
                })
            )
            .subscribe();
    }

}
